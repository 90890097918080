.lottery-heat-popup-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
}

.lottery-heat-popup-main-container {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 520px;
    height: 400px;
}

@keyframes SponsorAnimationOpen {
    from { background-position: 0px; }
    to { background-position: -2080px; }
}

@keyframes SponsorAnimationRepeat {
    from { background-position: -2080px; }
    to { background-position: -5720px; }
}

.lottery-heat-popup-main-animation {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(70%);
    animation: 
        SponsorAnimationOpen 0.3s steps(4) forwards, 
        SponsorAnimationRepeat 0.5s steps(7) infinite 0.3s;
    background-image: url("../../images/animations/sponsor.png");
    background-size: cover;
    user-select: none;
}

@keyframes SponsorFadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.lottery-heat-popup-sponsor-text {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 17px;
    text-align: center;
    line-height: 17px;
    margin: 0px;
    width: 80%;
    height: 35%;
    animation: SponsorFadeIn 0.3s ease-in forwards;
    font-family: "mishmash";
    color: rgb(138, 228, 244);
    user-select: none;
}

.lottery-heat-popup-sponsor-image {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 45%;
    left: 58%;
    width: 107px;
    height: 29px;
    opacity: 0;
    animation: SponsorFadeIn 0.3s ease-in forwards ;
    pointer-events: none;
    user-select: none;
}

.lottery-heat-popup-confirm-button {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    animation: SponsorFadeIn 0.3s ease-in forwards;
}

.lottery-heat-popup-description-text {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    text-align: center;
    line-height: 20px;
    margin: 0px;
    width: 80%;
    height: 35%;
    animation: SponsorFadeIn 0.3s ease-in forwards;
    font-family: "mishmash";
    color: rgb(138, 228, 244);
    user-select: none;
}