.error-popup-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
}

.error-popup-mask {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    user-select: none;
}

.error-popup-main-container {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 551px;
    height: 344px;
}

.error-popup-main-background {
    width: 100%;
    height: 100%;
    user-select: none;
}

.error-popup-error-button {
    position: absolute;
    top: 82%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.error-popup-description-text {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    text-align: center;
    line-height: 20px;
    margin: 0px;
    width: 80%;
    height: 35%;
    font-family: "mishmash";
    color: white;
    user-select: none;
}