body, html {
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevent scrollbars */
  height: 100%;
  background-image: url('images/bg.jpg');
  background-size: cover;
  background-position: center;

   /* Apply grayscale filter */
}