.top-menu-container{
    position: absolute;
    width: 100%;
    height: 87px;
}

.top-menu-background{
    position: absolute;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    background-image: url('../images/header_bg.png');
    background-repeat: repeat-x;
}

.top-menu-withdraw-button{
    position: absolute;
    top: 30%;
    right: 0px;
    transform: translate(-50%, -50%);
}

.top-menu-deposit-button{
    position: absolute;
    top: 30%;
    right: 80px;
    transform: translate(-50%, -50%);
}

.top-menu-ticket-text {
    position: absolute;
    top: 30%;
    right: 150px;
    transform: translate(-50%, -50%);
    text-align: left;
    line-height: 30px;
    margin: 0px;
    color: white;
    width: 100px;
    height: 30px;
    font-family: "Allerta";
    pointer-events: none;
    user-select: none;
}

.top-menu-ticket-icon {
    position: absolute;
    top: 30%;
    right: 290px;
    width: 30px;
    height: 30px;
    transform: translate(-50%, -50%);
    user-select: none;
    pointer-events: none;
}

.top-menu-balance-text {
    position: absolute;
    top: 30%;
    right: 280px;
    transform: translate(-50%, -50%);
    text-align: left;
    line-height: 30px;
    margin: 0px;
    color: white;
    width: 140px;
    height: 30px;
    font-family: "Allerta";
    pointer-events: none;
    user-select: none;
}

.top-menu-balance-icon {
    position: absolute;
    top: 30%;
    right: 480px;
    width: 30px;
    height: 30px;
    transform: translate(-50%, -50%);
    user-select: none;
    pointer-events: none;
}

.top-menu-vote-text {
    position: absolute;
    top: 30%;
    right: 12%;
    right: 480px;
    transform: translate(-50%, -50%);
    text-align: left;
    line-height: 30px;
    margin: 0px;
    color: white;
    width: 100px;
    height: 30px;
    font-family: "Allerta";
    pointer-events: none;
    user-select: none;
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
}

.top-menu-vote-icon {
    position: absolute;
    top: 30%;
    right: 620px;
    width: 30px;
    height: 30px;
    transform: translate(-50%, -50%);
    user-select: none;
    pointer-events: none;
}