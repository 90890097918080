.progress-bar-container {
    position: absolute;
    width: 611px;
    height: 147px;
    top: 7.7%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.7);
    user-select: none;
}

.progress-bar-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.progress-bar-lottery-heat-button {
    position: absolute;
    top: 80%;
    left: 48%;
    transform: translate(-50%, -50%);
}

.progress-bar-lottery-text {
    position: absolute;
    top: 82%;
    left: 48%;
    transform: translate(-50%, -50%);
    text-align: center;
    line-height: 10px;
    margin: 0px;
    color: white;
    width: 200px;
    height: 20px;
    font-family: "DS-DIGI";
    color: yellow;
    pointer-events: none;
    user-select: none;
}


.progress-bar-good-job-image {
    position: absolute;
    width: 132px;
    height: 46px;
    top: 80%;
    left: 48%;
    transform: translate(-50%, -50%);
}

.progress-bar-nice-image {
    position: absolute;
    width: 80px;
    height: 46px;
    top: 80%;
    left: 48%;
    transform: translate(-50%, -50%);
}

.progress-bar-progress-grid {
    position: absolute;
    display: flex;
    width: 516px;
    height: 18px;
    top: 47%;
    left: 49.3%;
    transform: translate(-50%, -50%);
}

.progress-bar-progress-element-container {
    width: 6px;
    height: 6px;
}

.progress-bar-progress-element {
    position: relative;
    width: 5px;
    height: 5px;
    top: 50%;
    left: 50%;
    background-color: rgb(0, 255, 26);
    transform: translate(-50%, -50%);
}

@keyframes ProgressBarGiftAnimation {
    from { background-position: 0px; }
    to { background-position: -840px; }
}

.progress-bar-gift-animation {
    position: absolute;
    left: 85%;
    bottom: 34%;
    height: 70px;
    width: 70px;
    animation: ProgressBarGiftAnimation 1s steps(12) forwards infinite;
    background-image: url("../images/animations/progressBarGift.png");
    background-size: cover;
    user-select: none;
}