.dance-side-button-scale{
    position: absolute;
    width: 55px;
    height: 55px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dance-side-image{
    position: absolute;
    width: 65px;
    height: 65px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    user-select: none;
}