.giftbox-popup-note-container {
    position: absolute;
    width: 45px;
    height: 55px;
    transform: translate(-50%, -50%);
}

@keyframes NoteShakeAnimation {
    0%, 100% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(-10deg);
    }
    75% {
        transform: rotate(10deg);
    }
}

.giftbox-popup-note-animation-container {
    position: absolute;
    width: 100%;
    height: 100%;
}

.giftbox-popup-note-pop-up-animation {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.giftbox-popup-note-shake-animation {
    position: absolute;
    width: 100%;
    height: 100%;
    animation: NoteShakeAnimation 0.5s ease-in-out infinite;
}

.giftbox-popup-note-image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 45px;
    height: 55px;
    pointer-events: none;
    user-select: none;
    transform: translate(-50%, -50%)  scale(100%, 100%);
    background-image: url("../../images/note/note1.png");
    background-size: cover;
}