.giftbox-popup-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
}

.giftbox-popup-main-container {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 960px;
    height: 540px;
}

@keyframes GiftboxAnimationOpen {
    from { background-position: 0px; }
    to { background-position: -18240px; }
}

@keyframes GiftboxAnimationRepeat {
    from { background-position: -18240px; }
    to { background-position: -30720px; }
}

.giftbox-popup-main-animation {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: 
        GiftboxAnimationOpen 1s steps(19) forwards, 
        GiftboxAnimationRepeat 1s steps(13) infinite 1s;
    background-image: url("../../images/animations/giftbox.png");
    background-size: cover;
    user-select: none;
}

@keyframes GiftboxFadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.giftbox-popup-title-image {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 80%;
    left: 50%;
    width: 115px;
    height: 25px;
    opacity: 0;
    animation: GiftboxFadeIn 0.2s ease-in forwards;
    pointer-events: none;
    user-select: none;
}
@keyframes GiftboxBonusFadeIn {
    0%, 100% {
        opacity: 0;
    }
    5% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
}

.giftbox-popup-bonus-image {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 70%;
    left: 50%;
    width: 211px;
    height: 98px;
    opacity: 0;
    animation: GiftboxBonusFadeIn 2s ease-in forwards ;
    pointer-events: none;
    user-select: none;
}

.giftbox-popup-confirm-button {
    position: absolute;
    top: 86%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    animation: GiftboxFadeIn 0.2s ease-in forwards;
}

.giftbox-popup-notes-animation-container{
    position: absolute;
    width: 100%;
    height: 100%;
}