body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-gray) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root,
body,
html {
  --bg-gray: #333333;
  --bg-blue: #ebdbb2;
  --navbar-bg: #d5c4a1;
  --button-main: #546f80;
  --button-hover: #fabd2f;
  --button-click: #2e3c46;
  --info-text-color: #795f51;
  --blue-text-color: #795f51;
  --table-bg: #546f80;
  --table-border-color: #2e3c46;
  --icon-hover: #f1f0e1;
}

@font-face {
  font-family: "Averia Serif Libre";
  src: url("./assets/fonts/AveriaSerifLibre-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Averia Serif Libre";
  src: url("./assets/fonts/AveriaSerifLibre-Bold.ttf");
  font-weight: 700;
}
