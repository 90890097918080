.meme-icon-container {
    position: relative;
}

.meme-icon-image {
    position: absolute;
    width: 90%;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}